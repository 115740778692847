<template>
  <div class="commonTabs">
    <div :class="['commonTabsHeader', { 'commonTabsHeaderSticky': isSettingsVisible }]">
      <ul class="commonTabsList">
        <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{commonTab: true, activeTab: activeTabIndex === index}"
          @click="setUrlHash(tab.title)"
        >
          {{ tab.title }}
        </li>
      </ul>
      <div v-if="isSettingsVisible" class="commonTabsSettings">
        <slot name="settings" />
      </div>
    </div>
    <div class="commonTabsContent">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',

  data () {
    return {
      tabs: [],
      activeTabIndex: 0,
      isSettingsVisible: false
    }
  },

  mounted () {
    this.tabs = this.$children.filter(child => child.$options.name === 'Tab')
    window.addEventListener('hashchange', this.readUrlHash)
    this.readUrlHash()
  },

  beforeDestroy () {
    window.addEventListener('hashchange', this.readUrlHash)
  },

  methods: {
    selectTab (index) {
      this.tabs.forEach((tab, i) => {
        tab.isActive = index === i
      })
      this.activeTabIndex = index
      const activeTab = this.tabs[index]
      this.isSettingsVisible = activeTab.hasSettings
    },

    getIndexByTitle (title) {
      return this.tabs.findIndex(tab => tab.title.toLocaleLowerCase() === title.toLocaleLowerCase())
    },

    setUrlHash (tabTitle) {
      if (this.activeTabIndex === this.getIndexByTitle(tabTitle)) {
        return
      }
      window.location.hash = tabTitle.toLowerCase()
    },

    readUrlHash () {
      let hash = window.location.hash || ''
      hash = hash.startsWith('#') ? hash.substring(1) : hash
      const indexTab = Math.max(this.getIndexByTitle(hash), 0)
      this.selectTab(indexTab)
    }
  }
}
</script>

<style lang="scss" scoped>
.commonTabs {
  width: 100%;

  &List {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: $gap;

    @include display-less(phablet) {
      margin-bottom: $gap * 0.5;
    }
  }

  &Header {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: $gap * 0.5;
    margin-bottom: $gap * 1.5;
    padding-bottom: $gap * 0.5;
    justify-content: space-between;

    @include display-less(phablet) {
      flex-direction: column;
      align-items: flex-start;
    }

    &Sticky {
      top: 88px;
      z-index: 20;
      position: -webkit-sticky;
      position: -moz-sticky;
      position: -ms-sticky;
      position: -o-sticky;
      position: sticky;
      background-color: #1C0C32;

      @include display-less(desktop) {
        top: 64px;
      }
    }
  }
}

.commonTab {
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 2px solid transparent;
}

.activeTab {
  border-image: linear-gradient(0.25turn, #f87900, #fe4f45, #e419ba);
  border-image-slice: 1;
}
</style>
